import React, { memo } from 'react'
import { useDevice } from 'device'
import { constants, twcx } from 'helpers'

import { useVisibilityTrigger } from 'intersection-observer'
import { track } from 'analytics'
import { useAb } from 'hooks'
import { Image, Text } from 'components/dataDisplay'

import brandsDesktop from './images/brands-desktop.png?src-loader'
import brandsMobile from './images/brands-mobile.png?src-loader'
import brandsTransparentMobile from './images/brands-transparent-mobile.png?src-loader'

import messages from './messages'


type BrandsRowProps = {
  className?: string
  transparent?: boolean
}

const BrandsRow: React.FunctionComponent<BrandsRowProps> = (props) => {
  const { className, transparent = false } = props
  const isHomePageSocialProofBlockRedesignHidden = useAb(constants.abTests.homeSocialProofBlockRedesign) === 'a'

  const { isMobile } = useDevice()

  // TODO: Delete after CRO-613 ab test is over
  const socialVideoRef = useVisibilityTrigger(() => {
    if (isHomePageSocialProofBlockRedesignHidden) {
      track('Social video display')
    }
  })

  const mobileImage = transparent ? brandsTransparentMobile : brandsMobile

  return (
    <>
      <Image
        className={twcx('mt-24 w-full', isMobile ? null : 'px-12', className)}
        src={isMobile ? mobileImage : brandsDesktop}
        alt="Bvlgari, Tocca, Kenneth Cole, Michael Kors, Marc Jacobs, Balenciaga"
        sizes={isMobile ? '100vw' : '1136px'}
      />
      <Text className="mt-24 text-center" message={messages.note} style={isMobile ? 'p3' : 'p1'} ref={socialVideoRef} html />
    </>
  )
}

export default memo(BrandsRow)
